import React from 'react';
import './Rooms.css'; // Import CSS file
import tent1 from "../images/three-canvas-tent.svg"
import tent2 from "../images/villa.svg"
import tent3 from "../images/cortage.svg"
import tent4 from "../images/three-canvas-tent.svg"
import Image1 from '../images/image1.jpeg'; // Import image files
import Image2 from '../images/image2.jpeg';






function Rooms() {
 
  return (
    <div className="room-container">
       <div className="heading-room">     
          <h2>NIGHT STAY</h2>
          <p>
            Since Rishi Valley Resort was founded in 2024, we have been committed to providing our guests with the ultimate holiday experience. We have grown from a small family-owned business to a world-renowned resort that has hosted millions of visitors from around the globe.
            </p>
            <div className="four-icon-container">
            <div class="four-icon">
                <img src={tent1} alt="" />
                <p>5 Swiss Tents</p>
            </div>
            <div class="four-icon">
                <img src={tent2} alt="" />
                <p>6 Eco Mud Cottages</p>
            </div>
            <div class="four-icon">
                <img src={tent3} alt="" />
                <p>12 Villa Rooms</p>
            </div>
            <div class="four-icon">
                <img src={tent4} alt="" />
                <p>18 Lake View Rooms</p>
            </div>
            </div>
        </div>
        <div className="room-card-container">
        <div class="room-card">
            <img src={Image1} alt="" />
            <h2>Swiss Tents</h2>
            <p class="description">This cozy room offers a comfortable stay with a beautiful view of the city skyline.</p>
            <div class="features">
    <div class="feature-item"><span class="icon">🌬️</span> Air Conditioner</div>
    <div class="feature-item"><span class="icon">🛏️</span> King Size Bed</div>
    <div class="feature-item"><span class="icon">🚪</span> Wooden Almirah</div>
  </div>
            <button class="book-now-btn">Book Now</button>
        </div>

        <div class="room-card">
         <img src={Image2} alt="" />
         <h2>Master Rooms</h2>
         <p class="description">Experience luxury and elegance in this spacious suite with stunning ocean views.</p>
         <div class="features">
    <div class="feature-item"><span class="icon">🌬️</span> Air Conditioner</div>
    <div class="feature-item"><span class="icon">🛏️</span> King Size Bed</div>
    <div class="feature-item"><span class="icon">🚪</span> Wooden Almirah</div>
  </div>
         <button class="book-now-btn">Book Now</button>
        </div>
        

        </div>
       
        </div>
  )
}

export default Rooms;