import React from 'react'
import './Footer.css';

function Footer() {
  return (
         <footer class="rights-reserved-container">
          <div class="plans-pricing3">
            © 2024 Rishiresortvalley  All rights reserved.
          </div>
          <div class="rights-reserved-container-items">
            <div class="plans-pricing3">Terms of Service </div>
            <div class="plans-pricing3">Privacy Policy </div>
            <div class="plans-pricing3">Cookies </div>
          </div>
        </footer>
      
  )
}

export default Footer;
