import React from "react";
import './Gallery.css';
import Image1 from '../images/image1.jpeg'; // Import image files
import Image2 from '../images/image2.jpeg';
import Image3 from '../images/image3.jpeg';
import Image4 from '../images/image4.jpeg';
import Image5 from '../images/image5.jpeg';
import Image6 from '../images/image6.jpeg';
import Image7 from '../images/image7.jpeg';
import Image8 from '../images/image8.jpeg';
import Image9 from '../images/image9.jpeg';
import Image10 from '../images/image10.jpeg';
import Image11 from '../images/image11.jpeg';
import Image12 from '../images/image12.jpeg';
import Contactdetail from "./Contactdetail";


function Gallery() {
  return (
    <div>
      <section className="gallery-section">
        <div className="heading heading-gallery">
          <h2>
            Rishi Valley Resort in Pictures: A Visual Journey of Our Resort
          </h2>
        </div>
        <div className="gallery">
      <div className="grid">
        <div className="item">
          <img src={Image1} alt="Image1" />
        </div>
        <div className="item">
          <img src={Image2} alt="Image2" />
        </div>
        <div className="item">
          <img src={Image3} alt="Image3" />
        </div>
        <div className="item">
          <img src={Image4} alt="Image4" />
        </div>
        <div className="item">
          <img src={Image5} alt="Image5" />
        </div>
        <div className="item">
          <img src={Image6} alt="Image6" />
        </div>
        <div className="item">
          <img src={Image7} alt="Image7" />
        </div>
        <div className="item">
          <img src={Image8} alt="Image8" />
        </div>
        <div className="item">
          <img src={Image9} alt="Image9" />
        </div>
        <div className="item">
          <img src={Image10} alt="Image10" />
        </div>
        <div className="item">
          <img src={Image11} alt="Image11" />
        </div>
        <div className="item">
          <img src={Image12} alt="Image12" />
        </div>
        {/* Add more images as needed */}
      </div>
    </div>
      </section>

      <section className="resort-section"> <img src="" alt="" />Rishi Valley Resort</section>

      <Contactdetail/>
    </div>
  );
}

export default Gallery;
