
import React from 'react';
import './Navbar.css';
import Logo from "../images/logowbg.png"; // Import your logo image file here
import { FaUser } from "react-icons/fa";
import { useRef } from 'react';

const Navbar = ({ toggleContact }) => {
 
  return (
    <nav className="navbar">
      <div className="navbar-container">
        <img src={Logo} alt="Logo" className="navbar-logo" />
        <ul className="navbar-list">
          <li className="navbar-item"><a href="#" className="navbar-link">Home</a></li>
          <li className="navbar-item" > <a href="about-section" className="navbar-link">About</a></li>
          <li className="navbar-item"><a href="#" className="navbar-link">Gallery</a></li>
          <li className="navbar-item" onClick={toggleContact}><a href="#" className="navbar-link">Contact</a></li>
        </ul>
        <div>
          <button className="navbar-button"><FaUser /></button>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;