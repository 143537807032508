import React from "react";
import './Contactdetail.css';
import { FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";

function Contactdetail() {
  return (
    <div>
      <div className="contact-us">
        <div className="contact-heading">
          <h2>CONTACT US</h2>
        </div>

        <div className="contact-details">
          <div className="contact-text">
            <FaPhone />
            <p>
              <strong>Phone:</strong> 9837019174 / 9837917620 / 7906669032
            </p>
          </div>
          <div className="contact-text">
            <MdEmail />
            <p>
              <strong>Email:</strong> <a href="mailto::resortrishivalley@gmail.com" target="_top" >resortrishivalley@gmail.com</a>
            </p>
          </div>
          <div className="contact-text">
            <FaMapMarkerAlt />
            <p>
              <strong>Location:</strong> Rishi Valley Resorts , Gattu Ghat ,
              Nilkanth Road , Rishikesh , Uttrakhand
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contactdetail;
