
import './App.css';
import Contact from './Components/Contact';
import Navbar from './Components/Navbar';
import SecondNavbar from './Components/SecondNavbar';
import Hero from './Components/Hero';

import AboutSection from './Components/AboutSection';
import Gallery from './Components/Gallery';
import Footer from './Components/Footer';
import Rooms from './Components/Rooms';
import React, { useState } from 'react';

function App() {
    const [showContact, setShowContact] = useState(false);

    const toggleContact = () => {
    setShowContact(!showContact);
    };
  return (
    <div className="App">
      <Contact/>   
       <SecondNavbar/> 
       <Navbar toggleContact={toggleContact}/> 
       <Hero/> 
       
       <AboutSection /> 
       <Rooms/> 
       <Gallery/> 
       <Footer/> 

    </div>
  ); 
}

export default App;
