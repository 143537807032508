import React from 'react';
import './SecondNavbar.css';
import { FaPhone } from 'react-icons/fa'; // Import phone icon from react-icons library
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa'; // Import social media icons

const SecondNavbar = () => {
  return (
    <nav className="second-navbar">
      <div className="second-navbar-container">
        <div className="left-section">
          <p className="phone-number"><FaPhone /> 9837019174 / 9837917620 / 7906669032</p>
        </div>
        <div className="right-section">
          <a href="https://www.facebook.com/profile.php?id=61558989374531" className="social-icon" target='_blank'><FaFacebook /></a>
          <a href="https://www.instagram.com/rishivalleyresort/" className="social-icon"  target='_blank'><FaInstagram /></a>
        </div>
      </div>
    </nav>
  );
}

export default SecondNavbar;