import React from "react";
import "./Contact.css";
import Navbar from "./Navbar";
import backgroundImage from "../images/dsd.jpg"; // Import background image file
import { FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import Footer from "./Footer";
import SecondNavbar from "./SecondNavbar";

function Contact() {
  return (
    <div>

      <SecondNavbar/>
      <Navbar />

      <div
        className="hero"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="contact-content">
          <p>For Bookings and Enquiries</p>
          <h1>CONTACT US</h1>
        </div>
      </div>


      <div className="contact-us">

        <div className="contact-heading">
          <h2>CONTACT US</h2>
        </div>

        <div className="contact-details">
          <div className="contact-text">
            <FaPhone />
            <p>
              <strong>Phone:</strong> 9837019174 / 9837917620 /  7906669032
            </p>
          </div>
          <div className="contact-text">
            <MdEmail />
            <p>
              <strong>Email:</strong> <a href="mailto::resortrishivalley@gmail.com" target="_top" >resortrishivalley@gmail.com</a>
            </p>
          </div>
          <div className="contact-text">
            <FaMapMarkerAlt />
            <p>
              <strong>Location:</strong> Rishi Valley Resorts , Gattu Ghat , Nilkanth Road , Rishikesh , Uttrakhand
            </p>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Contact;
