import React from 'react';
import  { useState, useEffect } from 'react';
import './Hero.css';
import image1 from "../images/image1.jpeg";
import image2 from "../images/image2.jpeg";
import image3 from "../images/image3.jpeg";
import image4 from "../images/image4.jpeg";
import image5 from "../images/image5.jpeg";

const Hero = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [
    image1,
    image2,
    image3,
    image4,
    image5
    
    // Add more image paths as needed
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(currentIndex => (currentIndex + 1) % images.length);
    }, 2000); // Change image every 5 seconds

    return () => clearInterval(interval);
  }, []);

  const goToPreviousImage = () => {
    setCurrentImageIndex(currentIndex => (currentIndex === 0 ? images.length - 1 : currentIndex - 1));
  };

  const goToNextImage = () => {
    setCurrentImageIndex(currentIndex => (currentIndex + 1) % images.length);
  };
  return (
    <div className="hero" style={{ backgroundImage: `url(${images[currentImageIndex]})` }}>
      <button className="arrow-button left" onClick={goToPreviousImage}>
        &lt;
      </button>
      <button className="arrow-button right" onClick={goToNextImage}>
        &gt;
      </button>
      <div className="hero-content">
        <h1 className="hero-heading">Experience Paradise on Earth</h1>
        <p className="hero-subheading">Escape to a luxurious mountainfront resort with world-class amenities and breathtaking views.</p>
        <button className="book-now-button">Book Now</button>
      </div>
    </div>
  );
}

export default Hero;