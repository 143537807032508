import React from 'react';
import './AboutSection.css';
import AboutImage1 from '../images/aboutImage1.jpeg'; // Import your left image file here
import AboutImage2 from '../images/aboutImage2.jpg'; // Import your left image file here
import AboutImage3 from '../images/aboutImage3.jpg'; // Import your left image file here
import AboutImage4 from '../images/aboutImage4.jpg'; // Import your left image file here



const AboutSection = React.forwardRef((props, ref) => {
 
  return (
    
    <section ref={ref} id='about-section' className="about-section">
      <div className="container">
        <div className="heading">     
          <h2>About Rishi Valley Resort</h2>
        </div>
        <div className="content">
          <div className="image">
            <img src={AboutImage1} alt="Left Image" width="600" />
          </div>
          <div className="text">
            <h3>Our History</h3>
            <p>
            Since Rishi Valley Resort was founded in 2024, we have been committed to providing our guests with the ultimate holiday experience. We have grown from a small family-owned business to a world-renowned resort that has hosted millions of visitors from around the globe.
            </p>
          </div>
        </div>
        <div className="content">
          <div className="text">
            <h3>COMPANY PARTIES</h3>
            <p>
            At Rishi Valley Resort, we offer a variety of accommodations to suit any traveler's needs. From luxurious villas to cozy cabins, we have it all. All of our rooms are equipped with modern amenities and stunning views of the surrounding landscape.
            </p>
          </div>
          <div className="image">
            <img src={AboutImage2} alt="Left Image" width="600" />
          </div>
        </div>
        <div className="content">
        <div className="image">
            <img src={AboutImage3} alt="Left Image" width="600" />
          </div>
          <div className="text">
            <h3>destination wedding</h3>
            <p>
            At Rishi Valley Resort, we offer a variety of accommodations to suit any traveler's needs. From luxurious villas to cozy cabins, we have it all. All of our rooms are equipped with modern amenities and stunning views of the surrounding landscape.
            </p>
          </div>
         
        </div>
        <div className="content">
          <div className="text">
            <h3>Our Activities</h3>
            <p>
            Here is never a dull moment at Rishi Valley Resort. We offer a wide range of activities for guests of all ages, including hiking, swimming, and more. Our experienced staff is always on hand to help you plan your perfect day.
            </p>
          </div>
          <div className="image">
            <img src={AboutImage4} alt="Left Image" width="600" />
          </div>
        </div>
      </div>
    </section>
  );
})

export default AboutSection;